// 1  {"en": "Default"}  #99FF99
// 2  {"en": "Draft"}  #00FF00
// 3  {"en": "Active"}  orange
// 4  {"en": "Suspended"}  grey
// 5  {"en": "Cancelled"}  black

const slotStatuses = {
	1 : {"en": "Default", 	"color": "#99FF99"},
	2 : {"en": "Draft", 	"color": "#00FF00"},
	3 : {"en": "Active", 	"color": "orange"},
	4 : {"en": "Suspended", "color": "grey"},
	5 : {"en": "Cancelled", "color": "black"},
}

export default slotStatuses