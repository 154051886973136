<template>
    <div :id='"new-card-" + index'
        :ref='"new-card-" + index'
        class="new-calendar-card"
        v-on=" !isMovable || !isSelected ? { click: staticClick } : {}" 

        v-bind:style="{
            ...cardColors,
            height: `${slotHeight}px`,
            width: unselectedMoveIsActive ?
                `${ceilSize.selectedWidth}px` :
                null
        }"
        v-bind:class="{
            // 'day__select'   : isDaySelect && isSelected && !slot.state.isBusy && !compareIsOpen,
            'compare'       : compareIsOpen,
            'open-compare-animation' : compareAnimate && !compareIsOpen && isCompareMode,
            'close-compare-animation': !compareAnimate && compareIsOpen && isCompareMode,
            'change__column': isMovable && eventSlot.styles.isChangeColumn,
            'selected'      : isSelected,
            'touched'       : isTouched,
            'busy'          : eventSlot.state.isBusy,
            'past'          : placeIsPast,
        }">   
        <div class="varnish">
            <div class="line" v-bind:style="{
                background: cardStatus() ? cardStatus().color : null,
            }"></div>
        </div>
        <div class="time middle__info" 
        v-if="!isSelected && !compareAnimate && !unselectedMoveIsActive|| 
                isSelected && compareAnimate && !unselectedMoveIsActive">
            {{timeString}}
        </div>
        <div class="big-info" v-if="isSelected && !compareAnimate || unselectedMoveIsActive">
            <div class="card__header">
                <div class="slot__title" v-bind:style="{
                    'max-height': `${slotHeight > 35 ? slotHeight - 12 - 8 : 30}px` 
                }">
                    {{cardTitle()}}
                </div>
                <div class="time">
                    <div>{{timeString}}</div>
                    <div class="selector" v-if="isSlotToolbarSelected"></div>
                </div>
            </div>
            <div class="info" v-if="slotHeight > 35">
                <div class="trainer">
                    {{ eventSlot.state.insertIndex }}
                </div>
                <div v-if="maxAttenders">
                    {{eventSlot.settings.attenders || 0}}
                    /
                    {{maxAttenders || 0}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Draggabilly from 'draggabilly/draggabilly';
import { bus } from '../EventBus.js'

import AlertMixin from './mixins/AlertMixin.vue'

import Slot from '../../../scripts/slot';
import { dateIsPast, isEqualDate } from '../../../scripts/date.js';
import { globalBus } from '../../../GlobalEventBus';
import CMS from '../../../service/cms/service';
import router from '../../../router.js';
import { TABLE_MOVEMENT_ACTION_COPY, TABLE_MOVEMENT_ACTION_REPLACE } from '../../../scripts/tableMovementAction.js';
import slotStatuses from '../../../scripts/slotStatuses'

function eventToCoordinates(event) { 
	return {x: event.clientX, y: event.clientY}; 
}

const CHANGE_COLUMN_DISTANCE = 8

export default {
    name: "NewCard",
    mixins: [
        AlertMixin
    ],
    props: {
        movementStep: Number,
        eventSlot: Slot,
        index: [String, Number],
        isSelected: {
            type: Boolean,
            default: false,
        },
        isCompareMode: {
            type: Boolean,
            default: false,
        },
        isMovable: {
            type: Boolean,
            default: false,
        },
        replaceableSlot: [Object, null],
    },
    data() {
        return {
           
            period: this.eventSlot.cardPeriod,
            mainColor: this.eventSlot.settings.cellBackgroundColor || 'lavender',
            textColor: this.eventSlot.settings.cellTextColor,
            maxAttenders: this.eventSlot.settings.maxAttenders,
            trainer: "Автор",

            hour: 0,
            minute: 0,

            // insert
            placeIsPast: false,
            copyIsCreated: false,
            placeIsChanged: false,

            isTouched: false,
            isPreparationForDelete: false,
            supportTimeIsNeeded: false,

            settingsIsOpen: false,

            oneMinuteHeight: 0,
            lastDate: null,
            lastPlace: null,
            lastPointerDownDate: null,
            draggabilly: null,
            card: {},

            /* Unselected slot move */
            unselectedMoveIsActive: false,
            unselectedMoveEnabled: false,
			startPosX: 0,
			startPoxY: 0,
			columnRight: 0,
            tableHeight: 0,
            tableLeftSide: null,
            tableRightSide: null,
            tableSelectedColumn: null,
			tableHeightTopBorder: 0, 
            dragging: null,
            futureColumn: null,
            columnLeft: 0,

            staticMenuIsVisible: true,
            protectionStaticClick: false, // if true, staticClickHandler is not working
        };
    },
    computed: {
        ceilSize(){
            return this.$store.getters.tableCeilSize
        },
        startDate:{
            get(){
                return this.period.start.date
            },
            set(value){
                this.period.update(value)
            }
        },
        workEnd(){
            return this.$store.getters.calendarWorkEnd
        },
        workStart(){
            return this.$store.getters.calendarWorkStart
        },
        timeString(){
            let minute = this.minute;
            let hour = this.hour;

            if (minute < 10){
                minute = "0" + minute;
            }
                
            return hour + ":" + minute;
        },
        tableSlotMovementAction(){
            return this.$store.getters.tableSlotMovementAction
        },
        cardColors(){
            return {
                'background' : this.mainColor,
                'color'      : this.textColor ,
            }
        },
        compareIsOpen(){
            return this.eventSlot.compare.isActive
        },
        compareAnimate(){
            return this.eventSlot.compare.animate
        },
        slotHeight(){
            let height = this.eventSlot.period.duration / 60 * this.ceilSize.height
            if (this.hour != this.workStart.hour)
                height -= 1 
            return (height)
        },
        isSlotToolbarSelected(){
            let slotToolbarSelectedSlots = this.$store.getters.tableSlotsToolBar
            if (!slotToolbarSelectedSlots) return false
            let isExists = Boolean(slotToolbarSelectedSlots.find((slot) => {
                return this.eventSlot.index == slot.index
            }))
            return isExists 
        },
        isToolbarOpen(){
            return Boolean(this.$store.getters.tableSlotsToolBar)
        }
    },
    created() {

        this.placeIsPast = dateIsPast(this.startDate)
        this.hour = this.startDate.getHours()
        this.minute = this.startDate.getMinutes()


        // If past slot is not in work time
        let calendarWorkHours = this.$store.getters.calendarWorkHours 
        let checkSlotStart = calendarWorkHours.includes(this.hour)
        let endHour = this.eventSlot.period.end.date.getHours()
        let checkSlotEnd = calendarWorkHours.includes(endHour)
        if (!checkSlotStart) {
            this.supportTimeIsNeeded = true

            this.$store.dispatch('set-support-work-time', {
                hour: this.hour,
                index: this.index
            })
        }
        if (!checkSlotEnd) {
            this.supportTimeIsNeeded = true

            this.$store.dispatch('set-support-work-time', {
                hour: endHour,
                index: this.index
            })
        }

        if (this.isCompareMode){
            bus.$emit('column-' + this.startDate.getDate(), 'check-compare-slots', {
                slotIndex: this.index,
            });
        }
    },
    watch: {
        movementStep: function () {
            this.draggabilly.options["grid"] = [1, this.movementStep * this.oneMinuteHeight];
        },
        isMovable: function () {
            this.toggleDraggableProp()
        },
        isSelected: function () {
            this.toggleDraggableProp()
        },
        tableSlotMovementAction: function(newValue) {
            if (newValue == TABLE_MOVEMENT_ACTION_COPY) {
                this.draggabilly.disable()
                this.copyModeEnable()
            } 
            else if (newValue == TABLE_MOVEMENT_ACTION_REPLACE) {
                this.copyModeDisable()
                this.draggabilly.enable()
            }
        },
        replaceableSlot: function (newValue) {
            if (newValue == null) {
                this.toggleDraggableProp()
            }
            else if (newValue !== this.eventSlot) {
                if (this.tableSlotMovementAction == TABLE_MOVEMENT_ACTION_COPY) {
                    this.copyModeDisable()
                } else if (this.unselectedMoveEnabled) {
                    this.unselectedMoveDisable()
                } else {
                    this.draggabilly.disable()
                }
            }
        },
    },
    mounted() {
        bus.$on('new-card-' + this.index, 'update-card-info', this.updateCardInfo)
        bus.$on('new-card-' + this.index, 'move-to-last-place', this.moveToLastPlace)


        this.card = this.$refs["new-card-" + this.index]
        this.oneMinuteHeight = this.ceilSize.height / 60
        this.draggabilly = new Draggabilly(this.card, {
            containment: document.getElementById("calendar-table"),
            grid: [1, this.movementStep * this.oneMinuteHeight]
        })

        if (!this.placeIsPast){
            this.draggabilly.on("pointerDown", this.pointerDown)
            this.draggabilly.on("dragStart", this.dragStart)
            this.draggabilly.on("dragMove", this.dragMove)
            this.draggabilly.on("dragEnd", this.dragStop)
            this.draggabilly.on("pointerUp", this.pointerUp)
            this.draggabilly.on("staticClick", this.staticClick)
        }

        this.setPosition()
        this.toggleDraggableProp()

        this.lastDate = new Date(this.startDate.getTime())
    },
    beforeDestroy() {
        if (this.draggabilly)
            this.draggabilly.destroy()
        console.log(`new-card: ${this.index} : card ${this.index} was destroed`)
    },

    destroyed() {    
        if (this.supportTimeIsNeeded) {
            this.$store.dispatch('update-support-work-time', {
                index: this.index
            })
        }
    },

    methods: {
        cardTitle() {
            if (this.index < 0) {
                if (this.eventSlot.title == "") 
                    return ("New slot")
                else    
                    return this.eventSlot.title    
            } else {
                if (this.eventSlot.title == "")
                    return `Slot ${this.index}`
                else 
                    return this.eventSlot.title
            }
        },
        cardStatus() {
            return slotStatuses[this.eventSlot.settings.stateId]
        },

        toggleDraggableProp(){
            let isCopyMode = this.tableSlotMovementAction == TABLE_MOVEMENT_ACTION_COPY
            if (!isCopyMode) {
                if(this.isMovable && this.isSelected && !this.placeIsPast){
                    this.unselectedMoveDisable()
                    this.draggabilly.enable()
                } else {
                    this.unselectedMoveEnable()
                    this.draggabilly.disable()
                }
            } else {
                if(this.isMovable && this.isSelected && !this.placeIsPast){
                    if (this.unselectedMoveEnabled)
                        this.unselectedMoveDisable()
                    this.copyModeEnable()
                    this.draggabilly.disable()
                } else {
                    if (this.unselectedMoveEnabled)
                        this.unselectedMoveDisable()
                    this.copyModeDisable()
                    this.draggabilly.disable()
                }
            }
        },
        copyModeEnable(){
            this.$el.addEventListener('touchstart', this.copyModeTouchStartHandler)
            this.$el.addEventListener('pointerdown', this.copyModePointerDownHandler)
            this.$el.addEventListener('pointermove', this.copyModePointerMoveHandler)
            this.$el.addEventListener('pointerup', this.copyModePointerUpHandler)
            this.$el.addEventListener('pointercancel', this.copyModePointerUpHandler)
        },
        copyModeDisable(){
            this.$el.removeEventListener('touchstart', this.copyModeTouchStartHandler)
            this.$el.removeEventListener('pointerdown', this.copyModePointerDownHandler)
            this.$el.removeEventListener('pointermove', this.copyModePointerMoveHandler)
            this.$el.removeEventListener('pointerup', this.copyModePointerUpHandler)
            this.$el.removeEventListener('pointercancel', this.copyModePointerUpHandler)
        },
        copyModeTouchStartHandler(event){
            event.preventDefault()
        },
        copyModePointerDownHandler(event){
            if (this.replaceableSlot && 
                (this.replaceableSlot.index !== this.eventSlot.index || 
                this.replaceableSlot.copySlotIndex != this.eventSlot.copySlotIndex))                
                return event.preventDefault()
            this.isTouched = true

            bus.$emit('calendar', 'allow-scale', {allow: false})
			bus.$emit('table', 'update-replaceable-slot', this.eventSlot)

			setTimeout(() => {
				if (this.isTouched) {
                    this.$el.setPointerCapture(event.pointerId);
                    window.navigator.vibrate(65)
                    this.$store.dispatch('set-test-event', event)
                    this.$store.dispatch('set-slot-to-copy', this.eventSlot)
                    setTimeout(() => this.dispatchNewEvent(event, 'pointerdown'), 50)
                    this.copyIsCreated = true
				}
			}, 350)
        },
        copyModePointerMoveHandler(event){
            if (this.replaceableSlot && 
                (this.replaceableSlot.index !== this.eventSlot.index || 
                this.replaceableSlot.copySlotIndex != this.eventSlot.copySlotIndex))  
                return event.preventDefault()
            if (this.copyIsCreated) {
                this.dispatchNewEvent(event, 'pointermove')
                if (this.staticMenuIsVisible) {
                    this.staticMenuIsVisible = false
                    globalBus.$emit("static-menu", "update-visible", {visible:false})
                }
            }
        },
        copyModePointerUpHandler(event){
            if (this.replaceableSlot && 
                (this.replaceableSlot.index !== this.eventSlot.index || 
                this.replaceableSlot.copySlotIndex != this.eventSlot.copySlotIndex))
                return event.preventDefault()
            this.isTouched = false
            this.dispatchNewEvent(event, 'pointerup')
            if (!this.staticMenuIsVisible) {
                this.staticMenuIsVisible = true
                globalBus.$emit("static-menu", "update-visible", {visible:true})
            }
            bus.$emit('calendar', 'allow-scale', {allow: true})
            bus.$emit('table', 'update-replaceable-slot', null)
        },

        unselectedMoveEnable(){
            this.unselectedMoveEnabled = true
            this.$el.addEventListener('touchstart', this.unselectedMoveTouchStartHandler)
            this.$el.addEventListener('pointerdown', this.unselectedMovePointerDownHandler)
            this.$el.addEventListener('pointermove', this.unselectedMovePointerMoveHandler)
            this.$el.addEventListener('pointerup', this.unselectedMovePointerUpHandler)
            this.$el.addEventListener('pointercancel', this.unselectedMovePointerUpHandler)
        },
        unselectedMoveDisable(){
            this.unselectedMoveEnabled = false
            this.$el.removeEventListener('touchstart', this.unselectedMoveTouchStartHandler)
            this.$el.removeEventListener('pointerdown', this.unselectedMovePointerDownHandler)
            this.$el.removeEventListener('pointermove', this.unselectedMovePointerMoveHandler)
            this.$el.removeEventListener('pointerup', this.unselectedMovePointerUpHandler)
            this.$el.removeEventListener('pointercancel', this.unselectedMovePointerUpHandler)
        },
        
        unselectedMoveTouchStartHandler(event){
            event.preventDefault()
        },
        
        unselectedMovePointerDownHandler(event){

            bus.$emit('table', 'update-replaceable-slot', this.eventSlot)
            bus.$emit('calendar', 'allow-scale', {allow: false})

            this.isTouched = true
            setTimeout(() => {
                if (!this.isMovable || this.placeIsPast)
                    return
                if (this.isTouched && this.replaceableSlot === this.eventSlot) {
                    this.$el.setPointerCapture(event.pointerId);
                    window.navigator.vibrate(65)
                    this.unselectedMoveIsActive = true
                    this.pointerDown(event)
                    this.dragStart(event)
                }
            }, 350)
        },

        unselectedMoveStart(event){
            let table = document.getElementById('table')
			let tableRect = table.getBoundingClientRect()
			let elemRect = this.$el.getBoundingClientRect()

			// Table support work time for the slots that was created with old work time 
			let supportWorkTimeTop = document.getElementById("support-work-time-top")
			let supportTopHeight = supportWorkTimeTop.getBoundingClientRect().height

			let supportWorkTimeBot = document.getElementById("support-work-time-bottom")
			let supportBotHeight = supportWorkTimeBot.getBoundingClientRect().height

            let columnId = this.startDate.getDate()
            let column = document.getElementById(`calendar-column-${columnId}`)
            let columnRect = column.getBoundingClientRect()
            this.columnLeft = tableRect.left - columnRect.left

			this.tableHeight = Math.round(tableRect.height - elemRect.height - supportBotHeight)
			this.columnRight = Math.round(tableRect.width - this.ceilSize.selectedWidth + this.columnLeft)
			this.tableHeightTopBorder = Math.round(supportTopHeight) 

			// Init start position of a drag and drop
            console.log('event :>> ', event);
            // - this.ceilSize.selectedWidth / 2
            let posX = event.pageX - 30 + this.columnLeft
            if (Math.abs(this.columnLeft) < this.columnRight)
                posX -= (event.clientX - elemRect.left)
            else
                posX -= this.ceilSize.selectedWidth - (event.clientX - elemRect.left)
			let posY = event.pageY + table.scrollTop - tableRect.top - elemRect.height / 2
            this.$el.style.top = `${posY}px`
            this.$el.style.left = `${posX}px`

			// Init start point 
			let {x, y} = eventToCoordinates(event);
            console.log('{x, y} :>> ', {x, y});
			this.dragging = {dx: posX - x, dy: posY - y}
        },

        unselectedMovePointerMoveHandler(event){
            if (this.replaceableSlot && this.replaceableSlot.index !== this.eventSlot.index)
                return event.preventDefault()
            if (!this.unselectedMoveIsActive || !this.isMovable || this.placeIsPast)
                return
            let {x, y} = eventToCoordinates(event);
			let posX = Math.round(Math.max(
                this.columnLeft, 
                Math.min(
                    x + this.dragging.dx,
                    this.columnRight,
            )))
      		let posY = Math.round(Math.max(
                this.tableHeightTopBorder, 
                Math.min(
                    y + this.dragging.dy, 
                    this.tableHeight
            )))

            this.$el.style.top = `${posY}px`
            this.$el.style.left = `${posX}px`
            this.slotReplaceHandler(event, posX - this.columnLeft, posY)
        },

        unselectedMovePointerUpHandler(event){
            this.isTouched = false
            this.unselectedMoveIsActive = false
            this.dragging = null
            if (!this.placeIsChanged) {
                this.staticClick()
                return
            }

            this.dragStop()
            this.pointerUp()
            bus.$emit('table', 'update-replaceable-slot', null)
        },


        dispatchNewEvent(event, eventName) {
			let target = document.getElementById("create-slot")
			const pointerDownEvent = new PointerEvent(eventName, {
				bubbles: true,
				clientX: event.clientX,
				clientY: event.clientY
			});

			target.dispatchEvent(pointerDownEvent);
		},

        slotReplaceHandler(event, posX, posY){
            this.checkPreparationForDelete(event.clientY ?? event.changedTouches[0].clientY)
            if (this.isPreparationForDelete)
                return

            if(!this.placeIsChanged)
                this.placeIsChanged = true
            
            this.calcFutureColumn(posX)
            if (!this.futureColumn)
                return
            
            // if (this.ratio == 0 ) {
            //     if (this.timeIsChanged()){
            //         let columnId = this.startDate.getDate()

            //         this.checkBusy(columnId, this.period)
            //     }
            //     this.lastDate = new Date(this.startDate.getTime())
            // }

            if (this.isCompareMode) {
                bus.$emit('column-' + this.startDate.getDate(), 'check-compare-slots', {
                    slotIndex: this.index,
                    period: this.period,
                })
            }

            let nearestDate = this.eventSlot.state.nearest.place

            let isChangeColumn = this.startDate.getDate() != this.futureColumn.date.getDate()
            let currentDate = isChangeColumn && nearestDate ? 
                nearestDate.getDate() : 
                this.startDate.getDate()
            bus.$emit('column-' + currentDate, 'update-slot-last-date', {
                time: this.eventSlot.state.nearest.place,
            })


            this.setTimeForPosition(posY);
            this.placeIsPast = dateIsPast(this.startDate);
           
            /**
             * X Axis movement
             */

            if (Math.abs(posX) < CHANGE_COLUMN_DISTANCE) {
                if (this.eventSlot.state.isBusy){
                    this.$store.dispatch('update-question-column', {
                        date: this.eventSlot.state.nearest.place,
                        color: this.mainColor,
                        duration: this.eventSlot.period.duration,
                        isNearestPlace: true,
                    })
                } else {
                    this.$store.dispatch('update-question-column', {date: null})
                }
                
                return;
            }

            // Update difference between current and hovered column
            let date = new Date(this.futureColumn.date)
            date.setHours(this.hour, this.minutes)
            this.placeIsPast = dateIsPast(date);
            if (this.placeIsPast || this.isPreparationForDelete){
                this.$store.dispatch('update-question-column', {date: null})
                return
            } 

            
            /* Show a future column or a nearest place inside them */


            let dateToShow = this.eventSlot.state.isBusy ?
                this.eventSlot.state.nearest.place :
                this.futureColumn.date

            this.$store.dispatch('update-question-column', {
                date: dateToShow,
                color: this.mainColor,
                duration: this.eventSlot.period.duration,
                isNearestPlace: this.eventSlot.state.isBusy,
            })


            if ( this.timeIsChanged() || this.dateIsChanged()){
                let period = this.period.projection(this.futureColumn.date)
                let columnID = this.futureColumn.date.getDate()
                this.checkBusy(columnID, period)
            }
            this.lastDate = new Date(this.futureColumn.date.getTime())
        },

        updateSlotTime(time) {
            this.hour = time.getHours();
            this.minute = time.getMinutes();
            let newDate = new Date(this.startDate.getTime())
            newDate.setHours(time.getHours(), time.getMinutes())
            this.startDate = newDate
        },
        updateSlotDate(newDate, isAfterPut=false) {

            this.updateSlotTime(newDate);
            this.setPosition();
            this.setTimeForPosition(this.draggabilly.position.y);
            console.log(`new-card: ${this.index} : card ${this.index} set training time : ${this.startDate}`);
            bus.$emit('table', 'update-slot-time', {
                index: this.index,
                date: this.startDate,
                isAfterPut: isAfterPut,
            });
        },
        updateCardInfo(newCardInfo){
            let {title, mainColor, textColor, maxAttenders, date, duration} = newCardInfo
            let isAfterPut = newCardInfo.isAfterPut ?? false

            if (title) this.eventSlot.title = title
            if (mainColor) this.mainColor = mainColor
            if (textColor) this.textColor = textColor
            if (maxAttenders) this.maxAttenders = maxAttenders

            if (date){
                this.updateSlotDate(date, isAfterPut)
            }
        },

		initTableColumnWidths(){
			let start = 0
			let tableHeader = this.$store.getters.tableHeader
			let selectedItem = tableHeader.selectedItem()

			let defaultWidth = this.ceilSize.defaultWidth
			let selectedWidth = this.ceilSize.selectedWidth

			this.tableLeftSide = tableHeader.leftSide().map(columnItem => {
				let item  = {
					start: start,
					end: start + defaultWidth, 
					date: columnItem.date
				}
				start = start + defaultWidth
				return item
			})

			this.tableSelectedColumn = {
				start: start,
				end: start + selectedWidth, 
				date: selectedItem.date
			}

			start = start + selectedWidth

			this.tableRightSide = tableHeader.rightSide().map(columnItem => {
				let item  = {
					start: start,
					end: start + defaultWidth, 
					date: columnItem.date
				}
				start = start + defaultWidth
				return item
			})
		},

		calcFutureColumn(posX){
			let slotStartX = posX
			let slotEndX = posX + this.ceilSize.selectedWidth
			let relativeSelectedColumn = this.compareWithSelectedColumn(slotStartX, slotEndX)

		
			// Slot to the LEFT of the selected column
			if (relativeSelectedColumn == 1) {
				this.futureColumn = this.tableLeftSide.find(columnItem => {
					return this.columnIncludesPoint(columnItem, slotStartX)
				})
			} 
			// Slot to the RIGHT of the selected column
			else if (relativeSelectedColumn == -1) {
				this.futureColumn = this.tableRightSide.find(columnItem => {
					return this.columnIncludesPoint(columnItem, slotEndX)
				})
			} 
			// Slot ABOVE the selected column
			else if (relativeSelectedColumn == 0) {
				this.futureColumn = this.tableSelectedColumn
			}
			else {
				this.futureColumn = null
			}
            if (this.futureColumn){
                console.log('future :>> ', {
                    posX,
                    futureColumn : this.futureColumn.date,
                    relativeSelectedColumn,
                })
            } else {
                console.log(`no column! : ${posX}`)
            }
 		},

        compareWithSelectedColumn(slotStartX, slotEndX){
			let diffStart = this.tableSelectedColumn.start - slotStartX
			let diffEnd = this.tableSelectedColumn.end - slotEndX
			if (diffStart > CHANGE_COLUMN_DISTANCE && diffEnd > CHANGE_COLUMN_DISTANCE)
				return 1
			else if (diffStart < CHANGE_COLUMN_DISTANCE*-1 && diffEnd < CHANGE_COLUMN_DISTANCE*-1)
				return -1
			else 
				return 0
		},

		columnIncludesPoint(column, posX){
			return posX >= column.start && posX <= column.end
		},
        dateTimeRound(roundedDate) {
            let hour = roundedDate.getHours()
            let minute = roundedDate.getMinutes()
            let minuteUnit = minute % 10

            let movementStep = this.movementStep

            if (movementStep != 1){
                if (minuteUnit < 5 && minuteUnit != 0) {
                    roundedDate.setMinutes(movementStep - minuteUnit + minute)
                }
                if (minuteUnit >= 5) {
                    roundedDate.setMinutes(10 - minuteUnit + minute)
                }
                if (minute >= 55) {
                    roundedDate.setHours(hour + 1, 0)
                }
            }


            this.hour = roundedDate.getHours()
            this.minute = roundedDate.getMinutes()
        },
        openSettings() {
            let calendarUid = this.$route.params.calendarUid
            sessionStorage.setItem("hora-last-calendar", calendarUid)
            router.replace({name: "slot-edit", params: {
                calendarUid: this.$route.params.calendarUid,
                slotId: this.eventSlot.index,
            }})
        },
        openAttend() {
            router.replace({name: "slot-attend", params: {
                calendarUid: this.$route.params.calendarUid,
                slotId: this.eventSlot.index,
            }})
        },
        openToolBar() {
            if (!this.isMovable) return             
            this.$store.dispatch('update-slot-toolbar', {slot: this.eventSlot})
        },
        setPosition() {
            if (!this.draggabilly) return

            let relativePosition = this.hour - this.workStart.hour + (this.minute / 60) - (this.workStart.minute / 60)
            let totalPosition = relativePosition * this.ceilSize.height

            if (this.hour != this.workStart.hour)
                totalPosition += 1
                    
            this.draggabilly.setPosition(0, totalPosition, 3)
        },
        staticClick() {

            // Static click is not support the selectDay
            // Dragabully is disable if column is not selected
            if (this.protectionStaticClick){
                this.protectionStaticClick = false
                return
            }

            if (!this.isSelected){
                this.selectDay()
                return
            }

            let isLongTab = this.isLongTab()

            if (this.isToolbarOpen){
                this.openToolBar()
                return
            }
            
            if (this.isCompareMode){
                if (this.isMovable && isLongTab){
                    this.eventSlot.setCompareWithAnimation(!this.eventSlot.compare.isActive)
                }  
                else if (!this.isMovable){
                    this.eventSlot.setCompareWithAnimation(!this.eventSlot.compare.isActive)
                }
                return
            }
            
            // If calendar is not personal
            if (!this.isDefaultCalendar()){
                this.isMovable ?
                    this.openSettings() :
                    this.openAttend()
                return
            }

            if (this.isMovable){
                this.openSettings()
            }
        },

        isLongTab(){
            if (!this.lastPointerDownDate) return false
            let now = new Date()
            return now.getTime() - this.lastPointerDownDate.getTime() > 450
        },

        timeIsChanged(){
            return this.lastDate.getMinutes() != this.minute || this.lastDate.getHours() != this.hour
        },

        dateIsChanged(){
            return this.lastDate.getDate() != this.startDate.getDate()
        },

        checkBusy(columnId, period){
            bus.$emit('column-' + columnId, "check-busy-place", {
                period: period,
                nearest: this.eventSlot.state.nearest,
            });
        },
        setTimeForPosition(pageY) {
            let hour;
            let minute;
            let noWorkingBorder = this.workStart.minute / 60 * this.ceilSize.height;
            let position = (pageY + noWorkingBorder) / this.ceilSize.height; // current position
            hour = this.$store.getters.calendarWorkHours[Math.trunc(position)] // current item in the table
            minute = (position % 1) * 60; // position in the item
            // if hout not in workHours
            if (hour == undefined || minute < 0) {
                return;
            }
            // if last work hour
            if (hour == this.workEnd.hour && minute == this.workEnd.minute)
                return;
            if (minute > 60) {
                hour += 1;
                minute = 0;
            }

            let checkDate = new Date(this.startDate)
            checkDate.setHours(hour, minute);           

            this.dateTimeRound(checkDate);
            
            if (!dateIsPast(checkDate)){
                this.period.update(checkDate)
            }
        },
        selectDay() {
            if (this.isSelected === false) {
                bus.$emit('calendar', 'select-date', {
                    date: this.startDate
                });
            }
        },
        checkPreparationForDelete(clientY){
            console.log('clientY :>> ', clientY);
            console.log('isInDeleteArea = window.innerHeight - clientY ', isInDeleteArea, window.innerHeight - clientY);
            let deleteActiveRadius = 70
            let isInDeleteArea = window.innerHeight - clientY < deleteActiveRadius
            if (isInDeleteArea && !this.isPreparationForDelete){
                this.isPreparationForDelete = true
                this.$store.dispatch('update-question-column', {date: null})
                bus.$emit('table', 'update-slot-delete-preparation', {isDelete: true})
            }

            if (!isInDeleteArea && this.isPreparationForDelete){
                this.isPreparationForDelete = false
                bus.$emit('table', 'update-slot-delete-preparation', {isDelete: false})
            }
        },
        deleteSlot(){

            let columnIndex = this.startDate.getDate()
            let slotId = this.period.index
            let calendarUid = this.$route.params.calendarUid

            CMS.slots.delete(calendarUid, slotId).then((data) => {
                if (data.err){
                    throw new Error(data.err)
                }
                bus.$emit('table', 'update-slot-delete-preparation', {isDelete: false})
                bus.$emit('column-' + columnIndex, 'delete-slot', {slot: this.eventSlot})
                this.draggabilly.destroy()
            }).catch((error) => {console.error(error)})
        },
        pointerDown(event) {
            if (this.replaceableSlot && this.replaceableSlot.index !== this.eventSlot.index) {
                return event.preventDefault()
            }

            this.lastPointerDownDate = new Date()
            if (!this.eventSlot.state.isBusy && !this.placeIsPast) {
                this.lastPlace = new Date(this.startDate.getTime())
            }

            bus.$emit('table', 'update-replaceable-slot', this.eventSlot)

            bus.$emit('calendar', 'allow-scale', {allow: false})
            this.isTouched = true
            setTimeout(() => {

                /*
                    1. if slot is still touched 
                    2. if slot is movable
                    3. if compare mode is not active
                    4. if position is not changed
                    5. if unselected move
                */

                let isAbleToOpenToolBar = this.isTouched &&
                                        this.isMovable && 
                                        !this.isCompareMode && 
                                        !this.placeIsChanged &&
                                        !this.unselectedMoveIsActive

                if (isAbleToOpenToolBar) {
                    this.openToolBar()
                    this.protectionStaticClick = true
                }   
            }, 450)
        },
        dragStart(event) {
            if (this.replaceableSlot && this.replaceableSlot.index !== this.eventSlot.index) {
                return event.preventDefault()
            }

            this.$store.dispatch('update-table-mode', {
                isQuestion: true,
                isChangePlace: true,
            })
            bus.$emit('column-' + this.startDate.getDate(), "update-slot-last-date", {
                lastDate: this.eventSlot.state.nearest.place,
            });

            this.initTableColumnWidths()
            this.unselectedMoveStart(event)
            globalBus.$emit("static-menu", "update-visible", {visible:false})
        },
        dragMove(event) {
            if (this.replaceableSlot && this.replaceableSlot.index !== this.eventSlot.index) {
                return event.preventDefault()
            }
            console.log('event :>> ', event);
            let posX = this.draggabilly.position.x - this.columnLeft
            let posY = this.draggabilly.position.y
            this.slotReplaceHandler(event, posX, posY)
        },
        dragStop(event) {
            if (this.replaceableSlot && this.replaceableSlot.index !== this.eventSlot.index) {
                return event.preventDefault()
            }

            bus.$emit('column-' + this.startDate.getDate(), 'update-slot-last-date', {
                time: this.lastPlace,
            });
            globalBus.$emit("static-menu", "update-visible", {visible:true})

            this.$store.dispatch('update-table-mode', {
                isQuestion: false,
                isChangePlace: false,
            })
        },
        pointerUp(event) {
            if (this.replaceableSlot && this.replaceableSlot.index !== this.eventSlot.index) {
                return event.preventDefault()
            }

            bus.$emit('table', 'update-replaceable-slot', null) 

            console.log(`slot pointer up
            delete : ${this.isPreparationForDelete},
            past : ${this.placeIsPast},
            insert : ${this.eventSlot.state.insertIndex !== undefined},
            busy : ${this.eventSlot.state.isBusy}
            copy : ${this.copyIsCreated}\n`)

            this.pointerUpHandler()

            bus.$emit('calendar', 'allow-scale', {allow: true})
            this.$store.dispatch('update-question-column', {date: null})
            this.setDefaultSettings();
            this.eventSlot.clearState()
        },
        goToNearest() {
            if (this.eventSlot.state.nearest.place) {
                console.log(`new-card: ${this.index} : go to nearest`);
                let nearest = this.eventSlot.state.nearest.place
                this.updateSlotTime(nearest)
                this.setPosition()
                return;
            }
            this.moveToLastPlace();
        },
        moveToLastPlace() {
            if (this.lastPlace) {
                console.log(`new-card: ${this.index} : go to last not busy`);
                let lastPlace = this.lastPlace
                this.updateSlotTime(lastPlace)
                this.setPosition();
                this.eventSlot.state.isBusy = false
                this.placeIsPast = false
            }
        },
        goToOriginColumn() {
            console.log(`new-card: ${this.index} : go to origin column`);
            if (this.eventSlot.state.isBusy){
                this.moveToLastPlace()
            }
            else{
                this.setPosition()
            }
        },

        pointerUpHandler(){

            if(!this.placeIsChanged){
                return 
            }

            if (this.isPreparationForDelete)
                this.deleteSlot()
            else if (this.placeIsPast)
                this.pointerUpPlaceIsPast() 
            else if (this.eventSlot.state.insertIndex)
                this.pointerUpInsertIntoGroup() 
            else if (this.eventSlot.state.isBusy)
                this.pointerUpPlaceIsBusy()
            else
                this.pointerUpPlaceIsFree()

            if (this.copyIsCreated)
                this.$nextTick().then(this.pointerUpCopyCreate)

            let isAllowed = !this.placeIsPast &&
                            !this.isPreparationForDelete &&
                            !this.eventSlot.state.isBusy &&
                            !this.eventSlot.state.insertIndex
            if (isAllowed) {
                this.goToOriginColumn()
            }
        },

        pointerUpPlaceIsPast(){
            if (this.lastPlace){
                this.moveToLastPlace();
            }
            else{
                if(this.eventSlot.state.nearest.place){
                    this.goToNearest()
                }
                else{
                    // TODO : go to next column
                }
            }
        
            this.$store.dispatch('update-question-column', {date: null})
            bus.$emit('table', 'update-slot-time', {
                index: this.index,
                date: this.startDate,
            })
            this.setDefaultSettings();
        },
        pointerUpInsertIntoGroup(){
            bus.$emit('column-' + currentDate.getDate(), 'insert-slot-into-group', {
                date: this.futureColumn.date,
                nextIndex: this.eventSlot.state.insertIndex,
                currentIndex: this.index,
            })
        },

        pointerUpPlaceIsBusy(){
            let nearest = this.eventSlot.state.nearest.place;
            if (!nearest){
                this.moveToLastPlace()
                return
            }

            let isChangeDate = this.startDate.getDate() != this.futureColumn.date.getDate()

            console.log(`       place is busy
                change-column: ${isChangeDate},
                nearest: ${nearest},            
            `)

            this.updateSlotTime(nearest)

            bus.$emit('table', 'slot-movement-action', {
                index: this.index,
                date: nearest,
                isChangeDate: this.isChangeDate,
            })
            this.setPosition()
        },

        pointerUpCopyCreate(){
            bus.$emit('table', 'create-slot-copy')
        },

        pointerUpPlaceIsFree(){
            let isChangeDate = this.startDate.getDate() != this.futureColumn.date.getDate()

            console.log(`       place is free
                change-column: ${isChangeDate},         
            `)

            let date = new Date(this.futureColumn.date.getTime())
            date.setHours(this.hour, this.minute)

            bus.$emit('table', 'slot-movement-action', {
                index: this.index,
                isChangeDate: isChangeDate,
                date: isChangeDate ? 
                    date:
                    this.startDate,
            })
        },

        setDefaultSettings() {
            bus.$emit('table', 'update-slot-delete-preparation', {isDelete: false})
            this.eventSlot.state.isBusy = false;
            this.placeIsChanged = false;
            this.isTouched = false;
            this.copyIsCreated = false
            this.eventSlot.state.nearest = {};
        },
        
        isDefaultCalendar(){
            let calendarUid = this.$route.params.calendarUid
            let defaultCalendarUid = this.$store.getters.getDefaultCalendar
            return calendarUid == defaultCalendarUid
        }
    },
    
}

</script>

<style scoped>
.new-calendar-card{
    position: absolute;
    /* border-top: 1px solid grey;
    border-bottom: 1px solid grey; */
    outline: 1px solid grey;
    background: white;
    width: 100%;
    cursor: pointer;
    font-size: 10.5px;
    z-index: 2;
    display: flex;
    background: white;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
div::selection {
   background: transparent;
}
div::-moz-selection {
   background: transparent;
}

.compare{
    width: calc(50% - 1px);
    right: 0;
    z-index: 1;
}

.middle__info{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.big-info{
    padding: 4px 5px 4px 8px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.varnish{
    position: absolute;
    height: inherit;
    width: inherit;
    z-index: 3;
    width:100%;
    height: 100%;
}

.line {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: inherit;
}

.selected{
    display: block;
    min-width: 50px;
}

input{
    width: fit-content;
    font-size:10.5px;
}
#max-places{
    width: 10px;
}
#card-title {
    width: 100px
}
.card__header{
    display: flex;  
    text-decoration-color: currentColor;
    font-size:12px;
    justify-content: space-between;
    height: 100%;
}
.slot__title{
    max-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.close-compare-animation{
    animation: size-down 0.4s reverse;
}

.open-compare-animation{
    animation: size-down 0.4s;
}

@keyframes size-down {
  from {width: calc(100% - 1px);}
  to   {width: 50%;}
}

.trainer{
    float: right;
}
.info{
    display:table-cell;
    vertical-align:bottom;
}
.time{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    font-size:12px;
}


.busy {
    background: #FF8989;
    opacity: .7;
}

.past {
    opacity: .7;
}

.touched{
    z-index: 4;
}
    

@keyframes fly {
    0%{
        transform: translateY(5px);
    }
    50%{
        transform: translateY(-5px);
    }
    100%{
        transform: translateY(5px);
    }
}
.day__select{
    background: rgb(201, 201, 245);
    /* animation: fly 3s infinite ease-in-out; */
    /* box-shadow: 10px 5px 5px black; */
}

.change__column{
    z-index: 1001;
}

.selector{
    height: 12px;
    width: 12px;
    border: 1px solid black;
    border-radius: 10px;
}
</style>