var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({ref:"new-card-" + _vm.index,staticClass:"new-calendar-card",class:{
        // 'day__select'   : isDaySelect && isSelected && !slot.state.isBusy && !compareIsOpen,
        'compare'       : _vm.compareIsOpen,
        'open-compare-animation' : _vm.compareAnimate && !_vm.compareIsOpen && _vm.isCompareMode,
        'close-compare-animation': !_vm.compareAnimate && _vm.compareIsOpen && _vm.isCompareMode,
        'change__column': _vm.isMovable && _vm.eventSlot.styles.isChangeColumn,
        'selected'      : _vm.isSelected,
        'touched'       : _vm.isTouched,
        'busy'          : _vm.eventSlot.state.isBusy,
        'past'          : _vm.placeIsPast,
    },style:(Object.assign({}, _vm.cardColors,
        {height: (_vm.slotHeight + "px"),
        width: _vm.unselectedMoveIsActive ?
            ((_vm.ceilSize.selectedWidth) + "px") :
            null})),attrs:{"id":"new-card-" + _vm.index}}, !_vm.isMovable || !_vm.isSelected ? { click: _vm.staticClick } : {}),[_c('div',{staticClass:"varnish"},[_c('div',{staticClass:"line",style:({
            background: _vm.cardStatus() ? _vm.cardStatus().color : null,
        })})]),(!_vm.isSelected && !_vm.compareAnimate && !_vm.unselectedMoveIsActive|| 
            _vm.isSelected && _vm.compareAnimate && !_vm.unselectedMoveIsActive)?_c('div',{staticClass:"time middle__info"},[_vm._v(" "+_vm._s(_vm.timeString)+" ")]):_vm._e(),(_vm.isSelected && !_vm.compareAnimate || _vm.unselectedMoveIsActive)?_c('div',{staticClass:"big-info"},[_c('div',{staticClass:"card__header"},[_c('div',{staticClass:"slot__title",style:({
                'max-height': ((_vm.slotHeight > 35 ? _vm.slotHeight - 12 - 8 : 30) + "px") 
            })},[_vm._v(" "+_vm._s(_vm.cardTitle())+" ")]),_c('div',{staticClass:"time"},[_c('div',[_vm._v(_vm._s(_vm.timeString))]),(_vm.isSlotToolbarSelected)?_c('div',{staticClass:"selector"}):_vm._e()])]),(_vm.slotHeight > 35)?_c('div',{staticClass:"info"},[_c('div',{staticClass:"trainer"},[_vm._v(" "+_vm._s(_vm.eventSlot.state.insertIndex)+" ")]),(_vm.maxAttenders)?_c('div',[_vm._v(" "+_vm._s(_vm.eventSlot.settings.attenders || 0)+" / "+_vm._s(_vm.maxAttenders || 0)+" ")]):_vm._e()]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }